import { template as template_001e90c012fa4cbc866239cc1f7edaac } from "@ember/template-compiler";
const FKControlMenuContainer = template_001e90c012fa4cbc866239cc1f7edaac(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
