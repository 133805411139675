import { template as template_8c4bb44a7712423eb807b327809bd8d1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8c4bb44a7712423eb807b327809bd8d1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
