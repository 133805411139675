import { template as template_77059d36755c4b6096b16a9c8a9e4590 } from "@ember/template-compiler";
const WelcomeHeader = template_77059d36755c4b6096b16a9c8a9e4590(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
