import { template as template_b303b6b5db484269a5661ff3900d0446 } from "@ember/template-compiler";
const FKLabel = template_b303b6b5db484269a5661ff3900d0446(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
