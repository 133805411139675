import { template as template_4866316eae6e4be7a42c53fc5e73a2cf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4866316eae6e4be7a42c53fc5e73a2cf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
